import React, {useEffect} from 'react';
import styles from './UploadConversionFormatExcel.module.scss';
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import Card from "../../common/card/Card";
import {Alert, Button, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {CheckOutlined, FileUploadOutlined, UploadFileOutlined} from "@mui/icons-material";
import {AiFillFileExcel} from "react-icons/ai";
import {correctAndExportCSV} from "../../services/conversionExcelGenerationService";
import {
    addRejectedPayoutInfluencer,
    deleteRejectedPayoutInfluencer,
    getAllPotentialInfluencers
} from "../../services/rejectedPayoutsService";
import GenericTable from "../../common/table/GenericTable";
import Toast from "../../common/toast/Toast";
import useToast from "../../common/hooks/useToast";

const UploadConversionFormatExcel = () => {

    const influencerOutputColumns = {};
    influencerOutputColumns.visibility = {};
    influencerOutputColumns.data = [
        {
            header: 'Influencer Id',
            accessorKey: 'influencer_id',
        },
        {
            header: 'Influencer Name',
            accessorKey: 'influencer_name',
        },
        {
            header: 'Actions',
            accessorKey: 'actions',
            Cell: (cell) => {
                return (
                    <div>
                        <Button variant="contained" color="error" size="small"
                                onClick={e => handleDeleteInfluencer(cell.row.original.influencer_id)}>Delete</Button>
                    </div>
                );
            }
        }
    ];
    const {toastContent, isToastOpen, toggleToast, setToastContent} = useToast();


    const handleDeleteInfluencer = async (affiliate_id) => {
        const payout = {
            affiliate_id: affiliate_id
        }
        const response = await deleteRejectedPayoutInfluencer(payout);
        if (response.status === 200) {
            setToastContent("Influencer deleted successfully");

            getAllInfluencersThatGetsPayout().then(() => {
                console.log("Influencers fetched");
            });
        } else {
            setToastContent("Failed to delete influencer");
        }
        toggleToast();
    }


    const [formData, setFormData] = React.useState(null);
    const [commissionRate, setCommissionRate] = React.useState(0);
    const [influencers, setInfluencers] = React.useState([]);
    const [newInfluencer, setNewInfluencer] = React.useState({});

    const getAllInfluencersThatGetsPayout = async () => {
        const response = await getAllPotentialInfluencers();
        if (response.status === 200) {
            setInfluencers(response.data);
        } else {
            console.error("Failed to fetch the file");
        }
    }

    useEffect(() => {
        getAllInfluencersThatGetsPayout().then(() => {
            console.log("Influencers fetched");
        });
    }, []);//eslint-disable-line react-hooks/exhaustive-deps

    const csvFileChanged = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        setFormData(formData);
    }

    const exportCsvToExcel = async () => {
        if (!formData || commissionRate === 0) {
            return;
        }
        formData.append('commissionRate', commissionRate);
        const response = await correctAndExportCSV(formData);

        if (response.status === 200) {
            const fileBlob = response.data;
            const fileURL = window.URL.createObjectURL(fileBlob);
            // Create a download link and trigger it
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = 'correct-conversions.xlsx';
            link.click();

            // Clean up the URL object after download
            window.URL.revokeObjectURL(fileURL);
        } else {
            console.error("Failed to fetch the file");
        }
    }

    const addCommissionRateToFormData = (e) => {
        setCommissionRate(e.target.value);
    }
    const addNewInfluencer = async () => {
        const response = await addRejectedPayoutInfluencer(newInfluencer);
        if (response.status === 200) {
            setToastContent("Influencer added successfully");

        } else {
            setToastContent("Failed to add influencer");
        }
        getAllInfluencersThatGetsPayout().then(() => {
            console.log("Influencers fetched");
        });
        toggleToast();
    }

    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>

            <Card title={"Upload Conversion Format CSV"} customIcon={<AiFillFileExcel/>}>
                <div className={styles.compContainer}>
                    <div className={styles.uploadExcelPart}>
                        <div>
                            <TextField
                                variant="outlined"
                                type="number"
                                size={"small"}
                                label={"UPLOAD SERVER-LOG CSV"}
                                sx={{width: "300px"}}
                                disabled={true}
                                value={"UPLOAD SERVER-LOG CSV"}
                                contentEditable={false}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton component="label">
                                            <FileUploadOutlined/>
                                            <input
                                                styles={{display: "none"}}
                                                type="file"
                                                accept=".csv"
                                                hidden
                                                onChange={csvFileChanged}
                                                name="[licenseFile]"
                                            />
                                        </IconButton>
                                    ),
                                }}
                            />
                            <div className={styles.alertMessage}>{formData && formData.get("file") &&
                                <Alert icon={<CheckOutlined fontSize="inherit"/>}
                                       severity="success">{formData.get("file").name}</Alert>}</div>
                        </div>

                        <TextField variant="outlined"
                                   type="text" size={"small"}
                                   value={commissionRate}
                                   label={"Commission Rate"}
                                   onChange={addCommissionRateToFormData}></TextField>
                    </div>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={exportCsvToExcel}
                        disabled={!Number(commissionRate) || !formData || commissionRate === 0}
                        startIcon={<UploadFileOutlined/>}>Upload</Button>
                </div>

            </Card>
            <Card title={"Exist Influencers"}>
                <div className={styles.addNewInfluencerContainer}>
                    <TextField
                        variant="outlined"
                        type="text"
                        size={"small"}
                        label={"Influencer Id"}
                        sx={{width: "300px"}}
                        value={newInfluencer.influencer_id}
                        onChange={(e) => setNewInfluencer({...newInfluencer, influencer_id: e.target.value})}
                    />
                    <TextField
                        variant="outlined"
                        type="text"
                        size={"small"}
                        label={"Influencer Name"}
                        sx={{width: "300px"}}
                        value={newInfluencer.influencer_name}
                        onChange={(e) => setNewInfluencer({...newInfluencer, influencer_name: e.target.value})}
                    />
                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        disabled={!newInfluencer.influencer_id || !newInfluencer.influencer_name}
                        onClick={addNewInfluencer}>Add Influencer
                    </Button>
                </div>
                <div className={styles.tableContainer}>
                    {!!influencers && influencers.length > 0 &&
                        <GenericTable tableData={influencers} outputColumns={influencerOutputColumns}/>}
                </div>
            </Card>
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    );
};

export default UploadConversionFormatExcel;

import {clientGetWrapper, clientPostWrapper} from "../api/Api";
import {
    ADD_REJECTED_CONVERSION_INFLUENCER,
    DELETE_REJECTED_CONVERSION_INFLUENCER,
    GET_ALL_REJECTED_CONVERSIONS_PAYOUT_INFLUENCERS
} from "../common/constants/routes";

export const getAllPotentialInfluencers = () => {
    return clientGetWrapper(GET_ALL_REJECTED_CONVERSIONS_PAYOUT_INFLUENCERS);
}
export const deleteRejectedPayoutInfluencer = (payout) => {
    return clientPostWrapper(DELETE_REJECTED_CONVERSION_INFLUENCER, payout);
}

export const addRejectedPayoutInfluencer = (payout) => {
    return clientPostWrapper(ADD_REJECTED_CONVERSION_INFLUENCER, payout);
}